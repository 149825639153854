import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'rsuite';
import { queryClient } from '../../App';
import { useAppMessageContext } from '../../appMessagesContext';
import { formUnitFormData } from '../../helpers/formHelpers';
import { useGetSelectedDealer } from '../../hooks/getDealersHook';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import { RequestKeys } from '../../store/keys';
import { CreateCLScheduleRequest } from '../../store/settings/actions';
import { PendingUnitRequest } from '../../store/types';
import {
  CreateUnitRequestData, FileFromResponse, getUnit, stockValidation, UpdateUnitRequest,
} from '../../store/unit/actions';
import { QuickEditForm } from '../InventoryManager/QuickEditModal';
import UnitPage, { UnitFormInterface } from '../UnitPage';
import { isEditDisabled } from '../../helpers';

function EditUnit() {
  const params = useParams<{id: string}>();
  const { showMessage } = useAppMessageContext();
  const navigate = useNavigateHook();
  const {
    responseData: unit, isFetching, error: unitReqError,
  } = getUnit({ id: params.id || '', dlRequest: true });
  console.log(unit);
  const selectedDealer = useGetSelectedDealer();
  const updateUnitRequest = UpdateUnitRequest();
  const postScheduleRequest = CreateCLScheduleRequest();
  const quickEditData = queryClient.getQueryData<QuickEditForm>([RequestKeys.QUICK_EDIT_DATA]);
  const disabledEdit = isEditDisabled(unit?.dealer_schedules);
  const formedInitials: UnitFormInterface = formUnitFormData(unit);
  const initialValues: UnitFormInterface = {
    ...formedInitials,
    specifics: {
      ...formedInitials.specifics,
      title: quickEditData?.title || formedInitials.specifics.title,
      status: quickEditData?.status || formedInitials.specifics.status,
    },
    details: {
      ...formedInitials.details,
      price: quickEditData?.price ? String(unit?.details?.price) : formedInitials.details.price,
      notes: quickEditData?.notes || formedInitials.details.notes,
    },
    images: {
      ...formedInitials.images,
      files: quickEditData?.images.files.length ? quickEditData?.images.files : formedInitials.images.files,
      filesFromResponse: quickEditData?.images.files.length ? quickEditData?.images.files as FileFromResponse[] : formedInitials.images.filesFromResponse,
    },
  };
  const onFormSubmit = (data: CreateUnitRequestData) => {
    if (params?.id) {
      updateUnitRequest.mutate({
        data,
        id: params?.id,
        initialStatus: initialValues?.specifics?.status?.value ? String(initialValues?.specifics?.status?.value) : '',
        showMessage,
        ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
      });
      queryClient.setQueryData<PendingUnitRequest[]>(
        [RequestKeys.PENDING_REQUESTS],
        (oldData) => {
          // eslint-disable-next-line eqeqeq
          const indexOfPrevData = (oldData || [])?.findIndex((el: PendingUnitRequest) => el?.unitID == params?.id);
          const pendingData: PendingUnitRequest = {
            isLocalNotification: true,
            isNewUnit: false,
            status: 'PENDING',
            unitID: params?.id,
            readed: false,
            updated_at: new Date().toISOString(),
          };
          const tempArr = [...(oldData || [])];
          if (Array.isArray(oldData) && indexOfPrevData >= 0) {
            // eslint-disable-next-line no-param-reassign
            tempArr[indexOfPrevData] = pendingData;
          } else {
            tempArr.push(pendingData);
          }
          return tempArr;
        },
      );
      handleSuccessNavigation();
    }
  };
  useEffect(() => {
    if (unitReqError?.response?.status === 403) {
      navigate(Pages.inventoryManager);
    }
  }, [unitReqError]);

  const handleSuccessNavigation = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (postScheduleRequest?.isSuccess) {
      showMessage({ type: 'success', message: 'Schedule created successfully' });
      postScheduleRequest.reset();
      handleSuccessNavigation();
    }
    if (postScheduleRequest?.isError) {
      showMessage({
        type: 'error',
        message: postScheduleRequest.error?.response?.data?.message
            || postScheduleRequest.error?.response?.statusText || 'Something went wrong',
      });
      postScheduleRequest.reset();
    }
  }, [postScheduleRequest?.isSuccess, postScheduleRequest?.isError]);

  const pendingRequests = queryClient.getQueryData<PendingUnitRequest[]>([RequestKeys.PENDING_REQUESTS]);
  const stockValidationMutation = stockValidation();

  const handleStockValidation = (value: string) => {
    stockValidationMutation?.mutate({
      stock: value,
      item_id: params?.id,
      ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
    });
  };
  const handleStockValidationReset = () => {
    stockValidationMutation?.reset();
  };

  const currentUnitError = pendingRequests?.find((unitReq) => unitReq?.unitID === params?.id);
  if (isFetching) {
    return <Loader center />;
  }
  return (
    <div>
      <UnitPage
        headerTitle="Edit Unit"
        valuesFromProps={initialValues}
        onFormSubmit={onFormSubmit}
        requestErrors={updateUnitRequest?.error?.response?.data?.errors || currentUnitError?.errors}
        stockValidationErrors={stockValidationMutation?.error?.response?.data?.errors}
        loading={updateUnitRequest.isPending || stockValidationMutation?.isPending}
        savedFeaturesList={unit?.features}
        isEditPage
        openSections={quickEditData?.defaultOpenSections}
        overlaySettings={unit?.user_overlay_settings}
        dealer={unit?.dealer}
        handleStockValidation={handleStockValidation}
        handleStockValidationReset={handleStockValidationReset}
        disableSave={disabledEdit}
      />
    </div>
  );
}

export default EditUnit;
