import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import React, {
  useEffect, useMemo, useReducer, useRef, useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { Loader } from 'rsuite';
import { queryClient } from '../../App';
import { useAppMessageContext } from '../../appMessagesContext';
import { getLocalStorage, roleHasAccess } from '../../helpers';
import { useGetSelectedDealer } from '../../hooks/getDealersHook';
import { getMeRequest } from '../../store/auth/actions';
import { SupperAdminRoles } from '../../store/constants';
import { LocalStorageKeys, RequestKeys } from '../../store/keys';
import {
  deleteBulkUnitsRequest, deleteUnitRequest,
  getInventoryManagerFilters, getUnits, getUnitsForPrint, ItemsPaginationParams, setActiveUnitsRequest, UnitType,
} from '../../store/unit/actions';
import { InventoryManagerContext } from './inventoryManagerContext';
import MainLayoutHeader from './Header';
import ControlPanel from './ControlPanel';
import CustomTable, { CustomTableProps } from '../../components/CustomTable';
import useResize from '../../hooks';
import {
  FiltersType,
} from './ControlPanel/Filters';
import { InventoryManagerDeleteModalProps } from './InventoryManagerDeleteModal';
import {
  ActionCell, CategoryCell,
  CreatedAtCell,
  ImageCell, LocationCell, MobileViewCell, NotesCell, PriceCell, SalesPriceCell, StatusCell, UpdatedAtCell,
} from './UnintsTableCells';
import { PendingUnitRequest } from '../../store/types';
import CustomCheckbox from '../../components/inputs/CustomCheckbox';
import RJIco from '../../assets/RJ_logo.webp';
import InvisibleSvg from '../../components/SvgElements/InvisibleSvg';
import PrintableItemTable from '../../components/PrintableTable/PrintableItemTable';

export type InventoryFiltersT = {
  params: ItemsPaginationParams
  filters: {
    locationFilter: FiltersType['location'],
    manufacturerFilter: FiltersType['manufacturer'],
    categoryFilter: FiltersType['category'],
    statusFilter: FiltersType['status'],
    conditionFilter: FiltersType['conditions'],
    search: string,
    inventoryType: FiltersType['inventoryType'],
    showFilters: boolean,
    limit: number,
    page: number,
  }
}

export interface InventoryManagerData extends Omit<UnitType, 'images_count' | 'created_at' | 'updated_at' | 'item_category'> {
  images_count: JSX.Element
  item_category: JSX.Element
  location: JSX.Element
  notes: JSX.Element
  current_status: JSX.Element
  price: JSX.Element
  sales_price: JSX.Element
  created_at: JSX.Element
  updated_at: JSX.Element
}
export const inventoryTypeOptions: FiltersType['inventoryType'][] = [
  { value: 'Active Inventory', label: 'Active Inventory', is_active: true },
  { value: 'Archived Inventory', label: 'Archived Inventory', is_active: false },
];
function InventoryManager() {
  const { showMessage } = useAppMessageContext();
  const selectedDealer = useGetSelectedDealer();
  const getMe = getMeRequest();
  const inventoryCashedParams = queryClient.getQueryData<InventoryFiltersT>([RequestKeys.INVENTORY_MANAGER_FILTERS]);
  const [locationFilter, setLocationFilter] = useState<FiltersType['location']>(inventoryCashedParams?.filters?.locationFilter || {
    name: 'All', is_main: false, is_placeholder: true,
  });
  const [manufacturerFilter, setManufacturerFilter] = useState<FiltersType['manufacturer']>(inventoryCashedParams?.filters?.manufacturerFilter || { value: 'All', label: 'All', is_placeholder: true });
  const [categoryFilter, setCategoryFilter] = useState<FiltersType['category']>(inventoryCashedParams?.filters?.categoryFilter || { name: 'All', label: 'All', is_placeholder: true });
  const [statusFilter, setStatusFilter] = useState<FiltersType['status']>(inventoryCashedParams?.filters?.statusFilter || { value: 'All', label: 'All', is_placeholder: true });
  const [conditionFilter, setConditionFilter] = useState<FiltersType['conditions']>(inventoryCashedParams?.filters?.conditionFilter || { value: 'All', label: 'All', is_placeholder: true });
  const [search, setSearch] = useState<string>(inventoryCashedParams?.filters?.search || '');
  const [inventoryType, setInventoryType] = useState<FiltersType['inventoryType']>(inventoryCashedParams?.filters?.inventoryType || inventoryTypeOptions[0]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { isMobile } = useResize();
  const [showFilters, setShowFilters] = useState(!!inventoryCashedParams?.filters?.showFilters);
  const [limit, setLimit] = useState<number>(inventoryCashedParams?.filters?.limit || 15);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const initialPage = Object.fromEntries(urlSearchParams.entries()).page ? Number(Object.fromEntries(urlSearchParams.entries()).page) : 1;
  const [page, setPage] = useState<number>(initialPage || inventoryCashedParams?.filters?.page || 1);
  const [permanentDelete, setPermanentDelete] = useState<boolean>(false);

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);
  const [printingMode, setPrintingMode] = useState<boolean>(false);

  const [params, setParams] = useReducer(
    (state: ItemsPaginationParams, newState: Partial<ItemsPaginationParams>) => ({
      ...state,
      ...newState,
    }),
    {
      ...inventoryCashedParams?.params,
      limit,
      page,
      is_inventory_manager: '1',
      ...(selectedDealer?.id ? { dealer_id: [selectedDealer?.id] } : { dealer_id: undefined }),
    },
  );
  const { responseData: managerFilters, refetch: refetchFilters } = getInventoryManagerFilters(
    { dealer_id: selectedDealer?.id || undefined },
    (getMe.isFetching),
  );
  useEffect(() => {
    if (selectedDealer?.id) {
      setParams({
        dealer_id: [selectedDealer?.id],
      });
    }
  }, [selectedDealer?.id]);
  const userReq = getMeRequest();
  const idFromStorage = getLocalStorage(LocalStorageKeys.DEALER_ID);
  const isAdmin = roleHasAccess(userReq?.data?.data.role, SupperAdminRoles);
  const adminConditions = idFromStorage ? !params?.dealer_id : false;
  const {
    responseData: unitsList, meta: unitsMeta, refetch: refetchUnits, isFetching: unitsIsFetching,
  } = getUnits(params, !isAdmin ? (userReq?.isFetching) : adminConditions);
  const {
    responseData: unitsForPrintList, isLoading: printingUnitsIsFetching,
  } = getUnitsForPrint({
    ...params,
    limit: undefined,
  }, !printingMode);
  const deleteRequest = deleteBulkUnitsRequest();
  const deleteOneRequest = deleteUnitRequest();
  const setActiveRequest = setActiveUnitsRequest();
  const refetchInventory = () => {
    refetchUnits();
  };
  queryClient.setQueryData<() => void>([RequestKeys.REFETCH_INVENTORY], () => refetchInventory);

  const optionsFromArr = (arr: string[]) => [{ value: 'All', label: 'All', is_placeholder: true }, ...arr.map((el: string) => ({
    value: el,
    label: el,
  }))];

  useEffect(() => { // handle filters change
    setParams({
      ...(search ? { search } : { search: undefined }),
      ...(!conditionFilter?.is_placeholder ? { condition: [String(conditionFilter?.value)] } : { condition: undefined }),
      ...(!statusFilter?.is_placeholder ? { status: [String(statusFilter?.value)] } : { status: undefined }),
      ...(!inventoryType?.is_active ? { archived: 1 } : { archived: undefined }),
      ...(!manufacturerFilter?.is_placeholder ? { manufacturer: [String(manufacturerFilter?.value)] } : { manufacturer: undefined }),
      ...(!categoryFilter?.is_placeholder ? { category_id: [String(categoryFilter?.id)] } : { category_id: undefined }),
      ...(!locationFilter?.is_placeholder ? { location_id: [Number(locationFilter?.id)] } : { location_id: undefined }),
      ...(selectedDealer?.id ? { dealer_id: selectedDealer?.id ? [Number(selectedDealer?.id)] : undefined } : { dealer_id: undefined }),
      page,
    });
    const formUpdaterData: InventoryFiltersT = {
      params: {
        ...(search ? { search } : { search: undefined }),
        ...(!conditionFilter?.is_placeholder ? { condition: [String(conditionFilter?.value)] } : { condition: undefined }),
        ...(!statusFilter?.is_placeholder ? { status: [String(statusFilter?.value)] } : { status: undefined }),
        ...(!manufacturerFilter?.is_placeholder ? { manufacturer: [String(manufacturerFilter?.value)] } : { manufacturer: undefined }),
        ...(!categoryFilter?.is_placeholder ? { category_id: [String(categoryFilter?.id)] } : { category_id: undefined }),
        ...(!locationFilter?.is_placeholder ? { location_id: [Number(locationFilter?.id)] } : { location_id: undefined }),
        ...(!inventoryType?.is_active ? { archived: 1 } : { archived: undefined }),
        ...(selectedDealer?.id ? { dealer_id: selectedDealer?.id ? [Number(selectedDealer?.id)] : undefined } : { dealer_id: undefined }),
      },
      filters: {
        locationFilter,
        manufacturerFilter,
        categoryFilter,
        statusFilter,
        conditionFilter,
        search,
        inventoryType,
        showFilters,
        limit,
        page,
      },
    };
    queryClient.setQueryData(
      [RequestKeys.INVENTORY_MANAGER_FILTERS],
      (oldVal: InventoryFiltersT) => ({
        params: {
          ...oldVal?.params,
          ...formUpdaterData.params,
        },
        filters: {
          ...oldVal?.filters,
          ...formUpdaterData.filters,
        },
      }),
    );
  }, [search, conditionFilter, statusFilter, inventoryType, manufacturerFilter, categoryFilter, locationFilter, selectedDealer?.id, page, limit, showFilters]);

  const locationOptions = managerFilters?.location?.map((location) => (
    {
      ...location,
      label: location.name,
    }
  )) || [];
  const categoryOptions = managerFilters?.category?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map((category) => (
      {
        ...category,
        label: category.name,
      }
    )) || [];

  const manufacturerOptions = optionsFromArr(
    [...new Set(managerFilters?.manufacturer.map((item) => item))]
      .sort(),
  );

  const statusOptions = optionsFromArr(
    [...new Set((managerFilters?.status || []).map((item) => item))]
      .sort(),
  );

  const conditionOptions = optionsFromArr(
    [...new Set((managerFilters?.condition || []).map((item) => item))]
      .sort(),
  );

  const componentPDF = useRef<HTMLDivElement>(null);
  const generatePDF = useReactToPrint({
    content: () => {
      if (componentPDF.current) {
        return componentPDF.current;
      }
      return null;
    },
    documentTitle: 'TF-Inventory',
    onAfterPrint: () => {
      setParams({
        limit,
        page,
      });
      setPrintingMode(false);
    },
    onBeforeGetContent: () => new Promise((resolve) => {
      setTimeout(() => {
        resolve(1);
      }, 500);
    }),
  });

  const handlePrinting = () => {
    setPrintingMode(true);
  };

  useEffect(() => {
    if (printingMode && unitsForPrintList?.length) {
      generatePDF();
    }
  }, [printingMode, unitsForPrintList]);

  const handleInventoryTypeChange = (type: FiltersType['inventoryType']) => {
    setInventoryType(type);
    handleLimitChange(15);
  };

  const contextInitialValues = useMemo(() => ({
    search,
    setSearch,
    locationFilter,
    setLocationFilter,
    manufacturerFilter,
    setManufacturerFilter,
    categoryFilter,
    setCategoryFilter,
    statusFilter,
    setStatusFilter,
    conditionFilter,
    setConditionFilter,
    inventoryType,
    setInventoryType: handleInventoryTypeChange,
    showFilters,
    setShowFilters,
    permanentDelete,
    setPermanentDelete,
    handlePrinting,
    page,
    setPage,
  }), [
    statusFilter, setStatusFilter,
    search, setSearch,
    locationFilter, setLocationFilter,
    manufacturerFilter, setManufacturerFilter,
    categoryFilter, setCategoryFilter,
    conditionFilter, setConditionFilter,
    inventoryType, setInventoryType,
    handleInventoryTypeChange,
    page, setPage,
    showFilters, setShowFilters,
    permanentDelete, setPermanentDelete,
    handlePrinting,
  ]);

  const handleItemSelect = (itemKeys: number[]) => {
    setSelectedItems(itemKeys);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setParams({
      page: newPage,
    });
  };
  const handleLimitChange = (lim: number) => {
    setLimit(lim);
    setParams({
      limit: lim,
    });
  };

  const actionMessage = inventoryType?.value === 'Active Inventory' ? 'Archive' : 'Delete';
  const resetRequests = () => {
    deleteOneRequest.reset();
    deleteRequest.reset();
    setActiveRequest.reset();
  };

  useEffect(() => {
    setSelectedItems([]);
  }, [inventoryType.value]);

  useEffect(() => {
    if (deleteRequest.isSuccess && !deleteRequest.isPending) {
      showMessage({ type: 'success', message: `Units(s) ${permanentDelete ? 'Delete' : actionMessage}d successfully` });
      refetchUnits();
      setSelectedItems([]);
      setPermanentDelete(false);
      resetRequests();
      refetchFilters();
    }
    if (deleteOneRequest.isSuccess) {
      showMessage({ type: 'success', message: `Units(s) ${permanentDelete ? 'Delete' : actionMessage}d successfully` });
      refetchUnits();
      resetRequests();
      refetchFilters();
      setPermanentDelete(false);
    }
    if (setActiveRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Units(s) Unarchived successfully' });
      refetchUnits();
      resetRequests();
      refetchFilters();
    }
    if (deleteOneRequest.isError || deleteRequest.isError) {
      setPermanentDelete(false);
    }
  }, [deleteRequest.isSuccess, deleteOneRequest.isSuccess, setActiveRequest.isSuccess, deleteOneRequest.isError, deleteRequest.isError]);
  const handleAcceptModal = (mode?: InventoryManagerDeleteModalProps['mode']) => {
    if (mode === 'Unarchive') {
      setActiveRequest.mutate({ ids: selectedItems });
    } else {
      deleteRequest.mutate({ ids: selectedItems, approveDelete: permanentDelete });
    }
  };
  const handleChangeUnitMode = (id: number, newMode?: InventoryManagerDeleteModalProps['mode']) => {
    if (newMode === 'Unarchive') {
      setActiveRequest.mutate({ ids: [id] });
    } else {
      const isPermanentDelete = newMode === 'Delete';
      deleteOneRequest.mutate({ id, params: { approve_delete: isPermanentDelete ? '1' : '0' } });
    }
  };
  const handleColumnSort = (column: string, order: 'desc' | 'asc') => {
    if (column !== 'primary_image') {
      setParams({
        order,
        sort: column,
      });
      queryClient.setQueryData(
        [RequestKeys.INVENTORY_MANAGER_FILTERS],
        (oldVal: InventoryFiltersT) => ({
          ...oldVal,
          params: {
            ...oldVal.params,
            order,
            sort: column,
          },
        }),
      );
    }
  };

  const selectCell: CustomTableProps<InventoryManagerData>['customSelectCell'] = ({
    data, onChange, checked,
  }) => {
    const isHidden = data?.website_configuration?.is_active === false;
    const isRacingJunkIntegrated = (!!selectedDealer?.rj_integration || !!getMe?.data?.data.rj_integration) && !!data?.website_configuration?.racing_junk;

    return (
      <div className="custom-select-container">
        {isHidden && <InvisibleSvg />}
        {isRacingJunkIntegrated && <img src={RJIco} alt="Racing Junk" className="rj-icon" />}
        <div>
          <CustomCheckbox
            value={data.id}
            inline
            checked={checked}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };
  const pendingRequests = useQuery<readonly PendingUnitRequest[]>({
    queryKey: [RequestKeys.PENDING_REQUESTS],
  })?.data;
  const pendingIds = pendingRequests?.filter((req) => req?.status === 'PENDING')
    ?.map((pendingReq) => pendingReq?.unitID);
  const errorIds = pendingRequests?.filter((req) => req?.status === 'ERROR')
    ?.map((pendingReq) => pendingReq?.unitID);
  const dynamicColumns = locationOptions.length > 1 ? columns : columns.filter((column) => column.key !== 'location');
  return (
    <StyledManagerPage>
      <InventoryManagerContext.Provider value={contextInitialValues}>
        <MainLayoutHeader title="Inventory Manager" />
        <ControlPanel
          locationOptions={locationOptions}
          manufacturerOptions={manufacturerOptions}
          statusOptions={statusOptions}
          conditionOptions={conditionOptions}
          selectedItems={selectedItems}
          inventoryTypeOptions={inventoryTypeOptions}
          onModalAccept={handleAcceptModal}
          categoryOptions={categoryOptions}
        />
        <div className={`table-wrapper ${showFilters && 'with-filters'}`}>
          {(printingUnitsIsFetching || printingMode)
          && <Loader center className="loader" />}
          <div className="printing" id="printing-table" ref={componentPDF}>
            <PrintableItemTable data={unitsForPrintList} />
          </div>
          <div className="main-table">
            <CustomTable<InventoryManagerData>
              loading={unitsIsFetching}
              onPageChange={handlePageChange}
              limit={(unitsMeta?.total && unitsMeta?.total < limit) ? unitsMeta?.total : limit}
              onLimitChange={handleLimitChange}
              page={page}
              totalPages={unitsMeta?.last_page}
              showTotalPages
              onColumnSort={handleColumnSort}
              serverSorting
              serverSortColumn={params?.sort}
              serverSortType={params?.order}
              totalItems={unitsMeta?.total}
              customSelectCell={selectCell}
              data={unitsList.map((dataRow: UnitType) => ({
                ...dataRow,
                current_status: <StatusCell item={dataRow} />,
                images_count: <ImageCell key={dataRow.id} item={dataRow} />,
                item_category: <CategoryCell item={dataRow} />,
                location: <LocationCell item={dataRow} />,
                notes: <NotesCell item={dataRow} />,
                price: <PriceCell item={dataRow} />,
                sales_price: <SalesPriceCell item={dataRow} />,
                created_at: <CreatedAtCell item={dataRow} />,
                updated_at: <UpdatedAtCell item={dataRow} />,
                actionButtons: <ActionCell item={dataRow} onModeChange={handleChangeUnitMode} inventoryType={inventoryType} refetchUnits={refetchUnits} />,
                ...(isMobile && { mobile_displaying: <MobileViewCell item={dataRow} refetchUnits={refetchUnits} /> }),
              }))}
              columns={!isMobile ? dynamicColumns
                : [dynamicColumns[1], {
                  label: 'mobile displaying',
                  key: 'mobile_displaying',
                }]}
              rowClassName={(rowData: UnitType) => {
                if (errorIds?.includes(rowData?.id?.toString())) {
                  return 'error';
                }
                if (pendingIds?.includes(rowData?.id?.toString())) {
                  return 'pending';
                }
                return '';
              }}
              onItemSelect={handleItemSelect}
              checkedKeysProp={selectedItems}
              selectable={!isMobile}
              showHeader={!isMobile}
              displayPagination
              displayTotal
            />
          </div>
        </div>
      </InventoryManagerContext.Provider>
    </StyledManagerPage>
  );
}

export default InventoryManager;

const StyledManagerPage = styled.div`
  .rs-table-row:not(.rs-table-row-header)  {
    .rs-checkbox-inline {
     margin-left: 0;
   }
  }
  .custom-select-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .rj-icon {
      max-width: 25px;
      margin-right: 5px;
    }
  }
  #printing-table {
    display: none;
  }
  @media print {
    body * {
      visibility: hidden;
    }
    #printing-table {
      visibility: visible;
      display: block;
    }
    header {
      display: none;
    }
    .controll-header {
      display: none;
    }
  }
  .pending {
    background-color: #ffd783;
  }
  .error {
    background-color: #ff8a84;
  }
  .rs-table-row {
    .rs-table-cell-first:not([role=columnheader]) {
      background-color: #f6f5f5;
    }
    .rs-table-cell-wrap{
      text-align: left;
    }
    :hover {
      .rs-table-cell-first {
        background-color: inherit!important;
      }
      &.error {
        .rs-table-cell {
          background-color: #ff8a84;
        }
      }
      &.pending {
        .rs-table-cell {
          background-color: #ffd783;
        }
      }
    }
  }
  .rs-table-cell {
    :hover {
      * {
        //background-color: inherit;
      }
    }
    background-color: inherit;
  }
  .table-wrapper {
    margin-top: 0;
    .loader {
      position: absolute;
    }
    @media screen and (max-width: 768px) {
      .pagination-wrapper {
        > :nth-child(1) {
          display: none;
        }
      }
    }

    &.with-filters {
      .fullHeightContainer {
        @media screen and (max-width: 1600px) {
          height: calc(100vh - 340px);
        }
        height: calc(100vh - 320px);
      }
    }

    @media screen and (max-width: 1600px) {
      .fullHeightContainer {
        height: calc(100vh - 282px);
      }
    }
  }

  position: relative;

  .get-help-wrapper {
    position: fixed;
    z-index: 999;
    bottom: 40px;
    right: 100px;
    @media screen and (max-width: 768px) {
      right: 30px;
    }

    button {
      padding: 2px 10px 0;
    }

    .text {
      margin-left: 5px;
      font-size: 14px;
    }
  }
`;

export type UnitsColumns = {
  label: string
  key: keyof UnitType | 'actionButtons' | 'images_count' | 'created_at' | 'notes' | 'status' | 'price' | 'current_status' | 'location' | 'exported' | 'sales_price'
  sortable?: boolean
  width?: number
  minWidth?: number
  fixed?: boolean | 'left' | 'right'
}

const columns: UnitsColumns[] = [
  {
    label: '',
    key: 'current_status',
    sortable: false,
    width: 40,
  },
  {
    label: 'Primary Image',
    key: 'images_count',
    width: 145,
    sortable: true,
  },
  {
    label: 'Stock #',
    key: 'stock',
    sortable: true,
    width: 105,
  },
  {
    label: 'Title',
    key: 'title',
    sortable: true,
    minWidth: 200,
  },
  {
    label: 'Year',
    key: 'year',
    sortable: true,
    width: 72,
  },
  {
    label: 'Manufacturer',
    key: 'manufacturer',
    sortable: true,
    width: 110,
  },
  {
    label: 'Category',
    key: 'item_category',
    width: 136,
  },
  {
    label: 'Model',
    key: 'model_name',
    width: 85,
  },
  {
    label: 'Price',
    key: 'price',
    sortable: true,
    width: 82,
  },
  {
    label: 'Sales price',
    key: 'sales_price',
    sortable: false,
    width: 100,
  },
  {
    label: 'Location',
    key: 'location',
    sortable: true,
    width: 106,
  },
  {
    label: 'Notes',
    key: 'notes',
    minWidth: 100,
  },
  {
    label: 'Updated at',
    key: 'updated_at',
    sortable: true,
    width: 119,
  },
  {
    label: 'Created',
    key: 'created_at',
    sortable: true,
    width: 90,
  },
  {
    label: '',
    key: 'actionButtons',
    width: 105,
    fixed: 'right',
  },
];
