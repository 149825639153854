import PlusRoundIcon from '@rsuite/icons/PlusRound';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../appMessagesContext';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomTable from '../../../components/CustomTable';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import { getMeRequest } from '../../../store/auth/actions';
import { MeType } from '../../../store/auth/types';
import { ChangeUserRoles, SupperAdminRoles } from '../../../store/constants';
import {
  CreateUserRequest,
  CreateUserRequestI,
  DeleteUsersRequest,
  UpdateUserRequest, UpdateUserRequestI,
} from '../../../store/settings/actions';
import { UsersI } from '../../../store/settings/requests';
import {
  AccountCell, BillingCell, ConfirmPasswordCell, PasswordCell, SaveButtonCell, ScheduleCell, UsernameCell,
} from './TableCells';
import UserSectionHeader from './UserSectionHeader';

export interface ChangedTableItemType extends Omit<UsersI, 'id' | 'created_at' | 'updated_at' | 'dealer_id' | 'is_dealer_admin'> {
    id?: number
    is_dealer_admin?: boolean
    access_billing?: boolean
    access_scheduler?: boolean
    fb_profiles?: boolean
    password?: string
    password_confirmation?: string
    dealer_id?: number
    isNew?: boolean
    tempId?: string
}

export interface UsersRowError {
  email?: string
  password?: string
  password_confirmation?: string
}
export interface UsersSectionProps {
  usersData: UsersI[]
  user: MeType | undefined
  refetchTable: () => void
  loading?: boolean
}

function UsersSection({
  usersData, user, refetchTable, loading,
}: UsersSectionProps) {
  const { showMessage } = useAppMessageContext();
  const selectedDealer = useGetSelectedDealer();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [changedTableItems, setChangedTableItems] = useState<ChangedTableItemType[]>([]);
  const [errors, setErrors] = useState<UsersRowError[]>([]);
  const [newUsers, setNewUsers] = useState<ChangedTableItemType[]>([]);
  const [rowsOnEdit, setRowsOnEdit] = useState<number[]>([]);
  const [savedWasClicked, setSavedWasClicked] = useState<number[]>([]);
  const [tempItemSavingId, setTempItemSavingId] = useState<number | null>(null);

  const onItemSelect = (selected: number[]) => {
    setSelectedItems(selected);
  };

  const createUserRequest = CreateUserRequest();
  const updateUserRequest = UpdateUserRequest();
  const deleteUsersRequest = DeleteUsersRequest();

  const usersTableData: ChangedTableItemType[] = useMemo(() => [
    ...usersData.filter((data) => !data.role.includes('dealer')).map(((userMap) => ({
      ...userMap,
      is_dealer_admin: userMap.role.includes('dealer-admin'),
      access_scheduler: userMap.role.includes('access-scheduler'),
      access_billing: userMap.role.includes('access-billing'),
    }))),
    ...newUsers,
  ], [usersData, newUsers, usersData.length]);
  const userReq = getMeRequest();

  const rowValidation = (index: number, item: ChangedTableItemType) => {
    const err = [...errors];
    err[index] = {};
    if (item?.password !== item?.password_confirmation) {
      err[index] = {
        ...err[index],
        password: 'Passwords do not match',
        password_confirmation: 'Passwords do not match',
      };
    }
    if (item.password && item?.password?.length < 8) {
      err[index] = {
        ...err[index],
        password: 'The password must be longer than 8 characters ',
      };
    }
    setErrors(err);
    return !Object.keys(err[index] || {}).length;
  };

  const handleTableItemChange = (index: number, key: keyof ChangedTableItemType, val: string | boolean) => {
    setChangedTableItems((prevState) => {
      const tempArr = [...prevState];
      tempArr[index] = { ...tempArr[index], [key]: val };
      return tempArr;
    });
  };
  const handleTableRefetch = () => {
    setNewUsers(() => []);
    setChangedTableItems(() => []);
    refetchTable();
  };
  const handleAddUser = () => {
    const randomKey = (Math.random() + 1).toString(36).substring(7);
    setNewUsers((prev: ChangedTableItemType[]) => ([...prev, {
      email: '', password: '', password_confirmation: '', is_dealer_admin: false, isNew: true, tempId: randomKey, role: [], access_billing: false, access_scheduler: false,
    }]));
    setRowsOnEdit((prev) => ([...prev, usersTableData.length]));
  };
  const handleRowSave = (index: number, item: ChangedTableItemType, itemID: ChangedTableItemType['id']) => {
    const isValid = rowValidation(index, item);
    setSavedWasClicked((prevState) => [...new Set([...prevState, index])]);
    if (isValid) {
      if (itemID) {
        const initialUserData = usersTableData.find((usr) => usr.id === itemID);
        const asArray = Object.entries({
          email: item?.email,
          password: item?.password,
          password_confirmation: item?.password_confirmation,
          is_dealer_admin: Object.prototype.hasOwnProperty.call(item, 'is_dealer_admin') ? item.is_dealer_admin : !!initialUserData?.role.includes('dealer-admin'),
          access_billing: Object.prototype.hasOwnProperty.call(item, 'access_billing') ? item.access_billing : !!initialUserData?.role.includes('access-billing'),
          access_scheduler: Object.prototype.hasOwnProperty.call(item, 'access_scheduler') ? item.access_scheduler : !!initialUserData?.role.includes('access-scheduler'),
        });
        const filtered = asArray.filter(([, value]) => value !== undefined && value !== '');
        const formData: UpdateUserRequestI = Object.fromEntries(filtered);
        updateUserRequest.mutate({
          data: {
            ...formData,
            ...{ ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }) },
          },
          id: itemID,
        });
      } else {
        setTempItemSavingId(index);
        const formData: CreateUserRequestI = {
          email: item?.email || '',
          password: item?.password || '',
          password_confirmation: item?.password_confirmation || '',
          is_dealer_admin: !!item?.is_dealer_admin,
          access_billing: !!item?.access_billing,
          access_scheduler: !!item?.access_scheduler,
          fb_profiles: !!item?.fb_profiles,
          ...{ ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }) },
        };
        createUserRequest.mutate(formData);
      }
      setRowsOnEdit((prevState) => prevState.filter((el) => el !== index));
    }
  };

  const handleRemoveNewItem = () => {
    const tempArr = [...newUsers];
    if (tempItemSavingId) {
      tempArr.splice(tempItemSavingId, 1);
    }
    setNewUsers([...tempArr]);

    setChangedTableItems((prev) => {
      const tempChangedArr = [...prev];
      if (tempItemSavingId) {
        tempChangedArr.splice(tempItemSavingId, 1);
      }
      return [...tempChangedArr];
    });
    setTempItemSavingId(null);
  };

  useEffect(() => {
    if (createUserRequest.isError) {
      showMessage({ type: 'error', message: createUserRequest.error?.response?.data?.message || 'Something went wrong' });
    }
    if (createUserRequest.isSuccess && !createUserRequest.isPending) {
      handleRemoveNewItem();
      refetchTable();
      showMessage({ type: 'success', message: 'User(s) Created successfully' });
    }
    createUserRequest.reset();
  }, [createUserRequest.isError, createUserRequest.isSuccess]);

  useEffect(() => {
    if (updateUserRequest.isError) {
      showMessage({ type: 'error', message: updateUserRequest.error?.response?.data?.message || 'Something went wrong' });
    }
    if (updateUserRequest.isSuccess && !updateUserRequest.isPending) {
      handleTableRefetch();
      showMessage({ type: 'success', message: 'User(s) Updated successfully' });
    }
    updateUserRequest.reset();
  }, [updateUserRequest.isError, updateUserRequest.isSuccess]);

  useEffect(() => {
    if (deleteUsersRequest.isError) {
      showMessage({ type: 'error', message: deleteUsersRequest.error?.response?.data?.message || 'Something went wrong' });
    }
    if (deleteUsersRequest.isSuccess && !deleteUsersRequest.isPending) {
      handleTableRefetch();
      showMessage({ type: 'success', message: 'User(s) Deleted successfully' });
    }
    deleteUsersRequest.reset();
  }, [deleteUsersRequest.isError, deleteUsersRequest.isSuccess]);
  const handleModalAccept = () => {
    deleteUsersRequest.mutate({ ids: selectedItems });
  };

  const errorsFromResponse = updateUserRequest.error?.response?.data.errors || createUserRequest.error?.response?.data.errors;
  // eslint-disable-next-line eqeqeq
  const primaryEmail = (userReq?.data?.data.role.some((role) => SupperAdminRoles.includes(role)) && !selectedDealer?.id) ? userReq.data?.data.email
    : usersData.find((userData) => userData.role.includes('dealer'))?.email || user?.email || '';

  const disableUserRolesChange = user?.role.some((role) => ChangeUserRoles.includes(role));
  return (
    <Section>
      <UserSectionHeader showDelete={!!selectedItems.length} onModalAccept={handleModalAccept} primaryEmail={primaryEmail} />
      <div className="main-content">
        {loading ? (
          <div className="loader">
            <Loader center size="lg" color="red" />
          </div>
        )
          : (
            <CustomTable
              loading={!!loading}
              data={usersTableData.map((dataRow: ChangedTableItemType, index: number) => ({
                ...dataRow,
                hideSelect: !dataRow.id,
                username: <UsernameCell
                  item={dataRow}
                  onChange={(val: string) => handleTableItemChange(index, 'email', val)}
                  setEditMode={() => setRowsOnEdit((prev) => ([...prev, index]))}
                  editMode={rowsOnEdit.includes(index) || !!dataRow.isNew}
                  error={errors[index]?.email || (savedWasClicked.includes(index) ? errorsFromResponse?.email : '')}
                />,
                settings: <AccountCell
                  disabled={(user?.id === dataRow?.id) && !disableUserRolesChange}
                  item={dataRow}
                  onChange={(val) => {
                    handleTableItemChange(index, 'is_dealer_admin', val);
                  }}
                />,
                billing: <BillingCell
                  item={dataRow}
                  disabled={(user?.id === dataRow?.id) && !disableUserRolesChange}
                  onChange={(val) => {
                    handleTableItemChange(index, 'access_billing', val);
                  }}
                />,
                scheduler: <ScheduleCell
                  disabled={(user?.id === dataRow?.id) && !disableUserRolesChange}
                  item={dataRow}
                  onChange={(val) => {
                    handleTableItemChange(index, 'access_scheduler', val);
                  }}
                />,
                password: <PasswordCell
                  tableItem={changedTableItems[index]}
                  onChange={(val) => handleTableItemChange(index, 'password', val)}
                  error={errors[index]?.password || (savedWasClicked.includes(index) ? errorsFromResponse?.password : '')}
                />,
                password_confirmation: <ConfirmPasswordCell
                  tableItem={changedTableItems[index]}
                  onChange={(val) => handleTableItemChange(index, 'password_confirmation', val)}
                  error={errors[index]?.password_confirmation}
                />,
                save: <SaveButtonCell
                  onSave={() => handleRowSave(index, changedTableItems[index], dataRow.id)}
                />,
              }))}
              columns={columns}
              onItemSelect={onItemSelect}
              hideSelectHeader
              displayPagination={false}
              displayTotal={false}
              fillHeight={false}
              autoHeight
              fullHeightContainer={false}
            />
          )}
        <div className="button-wrap">
          <CustomButton className="add-user-button" onClick={handleAddUser}>
            <PlusRoundIcon color="#fff" className="ico" />
            <span>Add User</span>
          </CustomButton>
        </div>
      </div>
    </Section>
  );
}
export default UsersSection;

const Section = styled.div`
.main-content {
  #table-wrapper {
    margin-left: 0;
  }
  .button-wrap {
    width: 160px;
    .add-user-button {
      display: flex;
      align-items: center;
      .ico {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}
`;

const columns = [
  {
    label: 'Username / Email',
    key: 'username',
    width: 250,
  },
  {
    label: 'Settings',
    key: 'settings',
  },
  {
    label: 'Billing',
    key: 'billing',
  },
  {
    label: 'Scheduler',
    key: 'scheduler',
  },
  {
    label: 'New Password',
    key: 'password',
    width: 250,
  },
  {
    label: 'Confirm Password',
    key: 'password_confirmation',
    width: 250,
  },
  {
    label: '',
    key: 'save',
    width: 120,
  },

];
