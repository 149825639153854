import { useEffect } from 'react';
import { queryClient } from '../../App';
import { useAppMessageContext } from '../../appMessagesContext';
import { useGetSelectedDealer } from '../../hooks/getDealersHook';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import { RequestKeys } from '../../store/keys';
import { CreateCLScheduleRequest } from '../../store/settings/actions';
import { PendingUnitRequest } from '../../store/types';
import { CreateUnitRequest, CreateUnitRequestData, stockValidation } from '../../store/unit/actions';
import UnitPage from '../UnitPage';

function AddUnit(): JSX.Element {
  const { showMessage } = useAppMessageContext();
  const navigate = useNavigateHook();
  const createUnitRequest = CreateUnitRequest();
  const postScheduleRequest = CreateCLScheduleRequest();
  const selectedDealer = useGetSelectedDealer();

  const onFormSubmit = (data: CreateUnitRequestData, newUnitID: string) => {
    createUnitRequest.mutate({
      data, showMessage, newUnitID, ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
    });
    queryClient.setQueryData<PendingUnitRequest[]>(
      [RequestKeys.PENDING_REQUESTS],
      (oldData) => {
        // eslint-disable-next-line eqeqeq
        const indexOfPrevData = (oldData || [])?.findIndex((el: PendingUnitRequest) => el?.unitID === newUnitID);
        const pendingData: PendingUnitRequest = {
          isLocalNotification: true,
          isNewUnit: true,
          status: 'PENDING',
          unitID: newUnitID,
          readed: false,
          updated_at: new Date().toISOString(),
        };
        const tempArr = [...(oldData || [])];
        if (Array.isArray(oldData) && indexOfPrevData >= 0) {
          // eslint-disable-next-line no-param-reassign
          tempArr[indexOfPrevData] = pendingData;
        } else {
          tempArr.push(pendingData);
        }
        return tempArr;
      },
    );
    navigate(Pages.inventoryManager);
  };

  useEffect(() => {
    if (createUnitRequest.isError && createUnitRequest.error?.response?.status === 422) {
      showMessage({ type: 'error', message: createUnitRequest.error?.response?.data?.message || 'Please fill required fields with correct data' });
    } else if (createUnitRequest.isError && createUnitRequest.error?.response?.status === 413) {
      showMessage({ type: 'error', message: createUnitRequest.error?.response.statusText });
    } else if (createUnitRequest.isError && createUnitRequest.error?.response?.data?.message) {
      showMessage({ type: 'error', message: createUnitRequest.error?.response?.data?.message });
    } else if (createUnitRequest.isError) {
      showMessage({ type: 'error', message: 'Something went wrong' });
    }
    if (createUnitRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Unit Created successfully' });
      createUnitRequest.reset();
    }
  }, [createUnitRequest.isError, createUnitRequest.isSuccess]);

  useEffect(() => {
    if (postScheduleRequest?.isSuccess) {
      showMessage({ type: 'success', message: 'Schedule created successfully' });
      postScheduleRequest.reset();
      navigate(Pages.inventoryManager);
    }
    if (postScheduleRequest?.isError) {
      showMessage({
        type: 'error',
        message: postScheduleRequest.error?.response?.data?.message
            || postScheduleRequest.error?.response?.statusText || 'Something went wrong',
      });
      postScheduleRequest.reset();
    }
  }, [postScheduleRequest?.isSuccess, postScheduleRequest?.isError]);

  const pendingRequests = queryClient.getQueryData<PendingUnitRequest[]>([RequestKeys.PENDING_REQUESTS]);
  const currentUnitError = pendingRequests?.find((unitReq) => unitReq?.isNewUnit);

  const stockValidationMutation = stockValidation();

  const handleStockValidation = (value: string) => {
    stockValidationMutation?.mutate({
      stock: value,
      ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
    });
  };
  const handleStockValidationReset = () => {
    stockValidationMutation?.reset();
  };
  return (
    <div>
      <UnitPage
        headerTitle="Add Unit"
        onFormSubmit={onFormSubmit}
        requestErrors={createUnitRequest?.error?.response?.data?.errors || currentUnitError?.errors}
        loading={createUnitRequest.isPending}
        handleStockValidation={handleStockValidation}
        stockValidationErrors={stockValidationMutation?.error?.response?.data?.errors}
        handleStockValidationReset={handleStockValidationReset}
        dealer={selectedDealer}
      />
    </div>
  );
}
export default AddUnit;
